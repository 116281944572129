import RDGPageLayout from '../components/decorators/RDGPageLayout'
import Link from "next/link"

export default function Custom404() {
    const title = "Page Not Found"
    return (
      <RDGPageLayout>
        <div className="container">
        <h1>{title}</h1>

        <p>Your page could not be found. The following options may help you find what you're looking for:</p>
          <ul>
          <li><Link href="/" >Start at the homepage</Link></li>
          <li>Email <Link href="mailto:icpsr-websupport@umich.edu" >icpsr-websupport@umich.edu</Link> for assistance.</li>
          </ul>
        </div>
      </RDGPageLayout>
    );
  }
